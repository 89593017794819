const _ = require('lodash')
module.exports = (boltInstance, initialPages, isMobileView, version) => {
    const masterPageMetaData = boltInstance.structure.masterPage.metaData
    if (version === 1 || masterPageMetaData.version === version) {
        return
    }

    const pages = _.sortBy(initialPages, v => v.structure.masterPage ? 1 : 0) //see test BOLT-830
    const structure = _.merge(...pages.map(p => p.structure))
    boltInstance.$runInBatch(() => {
        boltInstance.replaceStructure(structure)
        boltInstance.setIsMobileView(isMobileView)
    })
}
